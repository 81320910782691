
import { Vue, Options } from 'vue-class-component'
import { mapActions } from 'vuex'

@Options({
  methods: mapActions(['login', 'logout'])
})
export default class Login extends Vue {
  username = ''
  password = ''

  // Actions
  login!: (params: URLSearchParams) => Promise<void>
  logout!: () => Promise<void>

  get params (): URLSearchParams {
    const params = new URLSearchParams()

    params.append('username', this.username)
    params.append('password', this.password)

    return params
  }

  created (): void {
    this.logout()
  }

  async launchLogin (): Promise<void> {
    await this.login(this.params)

    this.$router.push('/')
  }
}
