import User from '@/models/User'
import axios from 'axios'
import { GetterTree, MutationTree, ActionTree } from 'vuex'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const userGetters = <GetterTree<User, any>>{
  loggedIn (state): boolean {
    return state.token !== ''
  },
  name (state): string {
    return state.name
  },
  email (state): string {
    return state.email
  },
  token (state): string {
    return state.token
  },
  privileges (state) {
    return state.privileges
  }
}

const UserModule = {
  state: new User(),

  getters: userGetters,

  mutations: <MutationTree<User>>{
    update (state, user) {
      state.token = user.data
      state.name = user.username

      axios.defaults.headers.common.Authorization = 'Bearer ' + user.data
    },
    updatePrivileges (state, privileges) {
      state.privileges = privileges
    },
    logout (state) {
      state.token = ''
      state.privileges = []
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: <ActionTree<User, any>>{
    setupAxios ({ getters }) {
      return new Promise((resolve, reject) => {
        const token = getters.token

        if (!token) {
          reject(Error('There is no valid token'))
        }

        axios.defaults.headers.common.Authorization = `Bearer ${token}`
        resolve(true)
      })
    },
    setupPrivileges ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/privileges')
          .then((response) => {
            commit('updatePrivileges', response.data)
            resolve(response.data)
          })
          .catch((err) => reject(err))
      })
    },
    login ({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/login', params)
          .then((response) => {
            commit('update', {
              username: params.get('username'),
              ...response.data
            })
            axios.get('/auth/privileges').then((privilegesResponse) => {
              commit('updatePrivileges', privilegesResponse.data)
            })
            resolve(response.data)
          })
          .catch((err) => reject(err))
      })
    },
    logout ({ commit }) {
      commit('logout')
    }
  }
}

export default UserModule
