import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center py-12 sm:px-6 lg:px-8 h-full" }
const _hoisted_2 = { class: "mt-8 sm:mx-auto sm:w-full sm:max-w-md" }
const _hoisted_3 = { class: "bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10" }
const _hoisted_4 = { class: "sm:mx-auto mb-6 sm:w-full sm:max-w-md text-center" }
const _hoisted_5 = { class: "text-3xl font-extrabold text-gray-900" }
const _hoisted_6 = { class: "mt-2 text-sm text-gray-600" }
const _hoisted_7 = {
  for: "username",
  class: "block text-sm font-medium text-gray-700"
}
const _hoisted_8 = { class: "mt-1" }
const _hoisted_9 = {
  for: "password",
  class: "block text-sm font-medium text-gray-700"
}
const _hoisted_10 = { class: "mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('Login.WelcomeToTDApp')), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('Login.SignInMessage')), 1)
        ]),
        _createElementVNode("form", {
          class: "space-y-6",
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('Login.Username')), 1),
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("input", {
                id: "username",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
                name: "username",
                type: "text",
                autocomplete: "email",
                required: "",
                class: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              }, null, 512), [
                [_vModelText, _ctx.username]
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('Login.Password')), 1),
            _createElementVNode("div", _hoisted_10, [
              _withDirectives(_createElementVNode("input", {
                id: "password",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                name: "password",
                type: "password",
                autocomplete: "current-password",
                required: "",
                class: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              }, null, 512), [
                [_vModelText, _ctx.password]
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.launchLogin && _ctx.launchLogin(...args))),
              class: "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            }, _toDisplayString(_ctx.$t('Login.LogInAction')), 1)
          ])
        ], 32)
      ])
    ])
  ]))
}