/**
 * Patient Model
 */
/* eslint-disable camelcase */
export default class Study {
  ref = 'foobar'
  id = 1
  author = 'Oscar'
  publication = new Date()

  // Demographic
  age_min = 18
  age_max = 65
  sex_male = true
  sex_female = false
  pregnant = false
  breast_feeding = false

  // ADHD
  adhd_inattentive = false
  adhd_combined = false
  adhd_hyperactive = false
  adhd_severity_clin = 0.5
  adhd_severity_pat = 0.5
  adhd_severity = 0.5
  cgi_s = 0.5
}
