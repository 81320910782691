import PatientModule from '@/store/modules/PatientModule'
import StudyModule from '@/store/modules/StudyModule'
import UserModule from '@/store/modules/UserModule'
import AppModule from '@/store/modules/AppModule'
import createPersistedState from 'vuex-persistedstate'
import { createStore } from 'vuex'
import abilityPlugin from './plugins/ability'

export default createStore({
  modules: {
    PatientModule,
    StudyModule,
    UserModule,
    AppModule
  },
  plugins: [createPersistedState(), abilityPlugin]
})
