import Recommendation from './Recommendation'
import Scale from './Scale'

/**
 * Patient Model
 */

/* eslint-disable camelcase */
export default class Patient {
  id = -1

  basic_info = {
    alias: '',
    /** @deprecated */
    birthdate: new Date('2014-12-20').toISOString().substr(0, 10),
    age: 0,
    sex: 0,
    pregnant: false,
    breast_feeding: false,
    weight: 0,
    visited: new Date().toISOString()
  }

  recommendation: Recommendation = new Recommendation()

  adhdSeverityScale: Scale = new Scale()
  adhdSeverityScaleTreatment: Scale = new Scale()

  patient_information = {
    systolic_blood_pressure: 0,
    diastolic_blood_pressure: 0,
    heart_rate: 0,
    accelerometer: false,
    adhd_inattentive: false,
    adhd_combined: false,
    adhd_hyperactive: false,
    adhd_severity: 0,
    cgi_s: 0,
    adequate_intellectual_functioning: true,
    wisc_asked: false,
    wisc_puntuation: '',
    education_level: 0,
    education_level_parent1: 0,
    education_level_parent2: 0,
    work_status: 0,
    work_status_parent1: 0,
    work_status_parent2: 0,
    soc_puntuation: 0,
    ae_puntuation: 0,
    alfb_adhd_puntuation_correct: 0,
    alfb_adhd_puntuation_incorrect: 0
  }

  tracking = {
    accelerometer: false,
    accelerometer2: false,
    sc_puntuation: 0,
    ae_puntuation: 0,
    alfb_health_puntuation: 0,
    alfb_adhd_puntuation_correct: 0,
    alfb_adhd_puntuation_incorrect: 0
  }

  comorbidities = {
    current_other_adhd_neurodes: false,
    history_other_adhd_neurodes: false,
    intel_def: false,
    current_comunic_dis: false,
    history_comunic_dis: false,
    asd: false,
    current_motor_dis: false,
    history_motor_dis: false,
    current_learn_dis: false,
    history_learn_dis: false,
    current_other_other_neurodes: false,
    history_other_other_neurodes: false,
    current_psychotic_dis: false,
    current_psychotic_dis_text: '',
    history_psychotic_dis: false,
    history_psychotic_dis_text: '',
    current_bipolar_dis: false,
    current_bipolar_dis_text: '',
    history_bipolar_dis: false,
    history_bipolar_dis_text: '',
    current_depress_dis: false,
    current_depress_dis_text: '',
    history_depress_dis: false,
    history_depress_dis_text: '',
    current_anxiety_dis: false,
    history_anxiety_dis: false,
    current_separat_anxiety_dis: false,
    history_separat_anxiety_dis: false,
    current_fobia_dis: false,
    history_fobia_dis: false,
    current_social_anxiety_dis: false,
    history_social_anxiety_dis: false,
    current_panic_dis: false,
    history_panic_dis: false,
    current_gad: false,
    history_gad: false,
    current_ocd: false,
    history_ocd: false,
    current_ptsd: false,
    history_ptsd: false,
    current_eating_dis: false,
    current_eating_dis_text: '',
    history_eating_dis: false,
    history_eating_dis_text: '',
    current_anorex: false,
    history_anorex: false,
    current_bulimia: false,
    history_bulimia: false,
    current_binge: false,
    history_binge: false,
    current_other_eating_dis: false,
    current_other_eating_dis_text: '',
    history_other_eating_dis: false,
    current_disrupt_impuls_conduct_dis: false,
    history_disrupt_impuls_conduct_dis: false,
    current_odd: false,
    history_odd: false,
    current_ied: false,
    history_ied: false,
    current_conduct_dis: false,
    history_conduct_dis: false,
    current_tobac_dis: false,
    history_tobac_dis: false,
    current_addict: false,
    history_addict: false,
    current_oh_dis: false,
    history_oh_dis: false,
    current_cann_dis: false,
    history_cann_dis: false,
    current_opi_dis: false,
    history_opi_dis: false,
    current_sed_dis: false,
    history_sed_dis: false,
    current_stimul_dis: false,
    history_stimul_dis: false,
    current_other_subst_dis: false,
    history_other_subst_dis: false,
    personality_dis: false,
    tap: false,
    bpd: false,
    current_other_pysh_dis: false,
    current_other_pysh_dis_text: '',
    history_other_pysh_dis: false,
    current_suicid_ideat: false,
    history_suicid_ideat: false,
    current_unstable_psyc_dis: false
  }

  contraindications = {
    current_medical_dis: false,
    history_medical_dis: false,
    current_epilepsy: false,
    history_epilepsy: false,
    current_unstable_medical_dis: false,
    current_unstable_medical_dis_text: '',
    counterindication_stimulant: false,
    counterindication_atx: false,
    counterindication_alfa: false,
    counterindication_bup: false,
    counterindication_mod: false,
    counterindication_tca: false
  }

  drug_anamnesis = {
    ever_mph: false,
    ever_amf: false,
    ever_atx: false,
    ever_alfa: false,
    current_any_drug: false,
    current_cns_drugs: false,
    current_psychotropics: false,
    current_tt_adhd: false,
    current_mph: false,
    current_amf: false,
    current_atx: false,
    current_alfa: false,
    current_other_drug_adhd: false,
    stimulant_responders: false,
    failure_mph: false,
    failure_amf: false,
    failure_atx: false,
    failure_alfa: false,
    failure_other_drug_adhd: false,
    sev_side_eff_mph: false,
    sev_side_eff_amf: false,
    sev_side_eff_atx: false,
    sev_side_eff_alfa: false,
    sev_side_eff_other_drug_adhd: false,
    current_antidepressants: false,
    start_antidepressants: false,
    current_benzo: false,
    start_benzo: false,
    current_antipsychot: false,
    start_antipsychot: false,
    current_anti_tobac: false,
    start_anti_tobac: false,
    current_anti_oh: false,
    start_anti_oh: false,
    current_opi: false,
    start_opi: false,
    current_mood_stabilizers: false,
    start_mood_stabilizers: false,
    current_anticonvulsivants: false,
    start_anticonvulsivants: false,
    current_other_drug_cns: false,
    start_other_drug_cns: false
  }

  intervention_anamnesis = {
    current_adhd_psychotherapy: false,
    start_adhd_psychotherapy: false,
    current_anxiety_disorder_psychotherapy: false,
    start_anxiety_disorder_psychotherapy: false,
    current_depressive_disorder_psychotherapy: false,
    start_depressive_disorder_psychotherapy: false,
    current_sud_psychotherapy: false,
    start_sud_psychotherapy: false,
    current_other_disorder_psychotherapy: false,
    start_other_disorder_psychotherapy: false
  }

  preferences = {
    adhd_sympt_pat: 0,
    CGI: 0,
    drug_use_prevention: 0,
    drug_use: 0,
    accidents: 0,
    academic_achivement: 0,
    appetite_decreased: 0,
    insomnia: 0,
    somnolence: 0,
    dizziness: 0,
    vomiting: 0,
    dry_mouth: 0,
    tic: 0,
    seizures: 0,
    vasovagal_syncope: 0,
    QoL: 0,
    unwanted_ae: 1,
    n_drop_ae_: 0,
    n_drop_: 0,
    child_participation: false,
    pat_over_clin_importance: 0
  }

  clinicPreferences = {
    adhd_sympt_clin: 0,
    CGI: 0,
    drug_use_prevention: 0,
    drug_use: 0,
    accidents: 0,
    academic_achivement: 0,
    appetite_decreased: 0,
    insomnia: 0,
    somnolence: 0,
    dizziness: 0,
    vomiting: 0,
    dry_mouth: 0,
    tic: 0,
    seizures: 0,
    vasovagal_syncope: 0,
    QoL: 0,
    n_drop_ae_: 0,
    n_drop_: 0
  }

  combinedPreferences = {
    adhd_sympt: 0,
    CGI: 0,
    drug_use_prevention: 0,
    drug_use: 0,
    accidents: 0,
    academic_achivement: 0,
    appetite_decreased: 0,
    insomnia: 0,
    somnolence: 0,
    dizziness: 0,
    vomiting: 0,
    dry_mouth: 0,
    tic: 0,
    seizures: 0,
    vasovagal_syncope: 0,
    QoL: 0,
    n_drop_ae_: 0,
    n_drop_: 0
  }

  satisfactions = {
    other_preferences: '',
    understandable: 0,
    useful: 0,
    easy_to_use: 0,
    participation_importance: 0
  }

  treatment = {
    any_treatment: false,
    treatment: '',
    date: new Date().toISOString().substr(0, 10)
  }

  trackTreatmentResult = {
    adherence: 0,
    taken_correctly: false,
    n_drop_: false,
    any_ae: false,
    n_drop_ae: false,
    appetite_decreased: false,
    insomnia: false,
    somnolence: false,
    dizziness: false,
    vomiting: false,
    dry_mouth: false,
    tic: false,
    seizures: false,
    vasovagal_syncope: false
  }

  treatmentResult = {
    adherence: 0,
    taken_correctly: false,
    adhd_severity: 0,
    cgi_s: 0,
    n_drop_: false,
    any_ae: false,
    n_drop_ae: false,
    appetite_decreased: false,
    insomnia: false,
    somnolence: false,
    dizziness: false,
    vomiting: false,
    dry_mouth: false,
    tic: false,
    seizures: false,
    vasovagal_syncope: false,
    global_sat_pat: 0,
    global_sat_clin: 0
  }
}

export function mergePreferences (
  combined: Patient['combinedPreferences'],
  patient: Patient['preferences'],
  clinic: Patient['clinicPreferences']
): Patient['combinedPreferences'] {
  for (const key of Object.keys(clinic)) {
    if (Object.keys(patient).includes(key)) {
      combined[key] = Math.round((patient[key] + clinic[key]) / 2)
    }
  }
  combined.adhd_sympt = Math.round(
    (patient.adhd_sympt_pat + clinic.adhd_sympt_clin) / 2
  )
  return combined
}
