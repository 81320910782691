import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '@/components/Login.vue'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    redirect: '/login'
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard.vue'),
    meta: {
      authenticate: true
    }
  },
  {
    path: '/patient/:alias/edit',
    name: 'PatientData',
    component: () => import(/* webpackChunkName: "PatientData" */ '@/views/PatientData.vue'),
    meta: {
      authenticate: true
    }
  },
  {
    path: '/comorbidity/:id/edit',
    name: 'Comorbidity',
    component: () => import(/* webpackChunkName: "Comorbidity" */ '@/views/Comorbidity.vue'),
    meta: {
      authenticate: true,
      next: 'Contraindications'
    }
  },
  {
    path: '/contraindications/:id/edit',
    name: 'Contraindications',
    component: () => import(/* webpackChunkName: "Contraindications" */ '@/views/Contraindications.vue'),
    meta: {
      authenticate: true,
      next: 'PharmacologicalAnamnesis'
    }
  },
  {
    path: '/pharmacologicalAnamnesis/:id/edit',
    name: 'PharmacologicalAnamnesis',
    component: () => import(/* webpackChunkName: "PharmacologicalAnamnesis" */ '@/views/PharmacologicalAnamnesis.vue'),
    meta: {
      authenticate: true,
      next: 'PsychotherapeuticInterventions'
    }
  },
  {
    path: '/psychotherapeuticInterventions/:id/edit',
    name: 'PsychotherapeuticInterventions',
    component: () => import(/* webpackChunkName: "PsychotherapeuticInterventions" */ '@/views/PsychotherapeuticInterventions.vue'),
    meta: {
      authenticate: true,
      next: 'Dashboard'
    }
  },
  {
    path: '/studies/:id',
    name: 'Studies',
    component: () => import(/* webpackChunkName: "Studies" */ '@/views/Studies.vue'),
    meta: {
      authenticate: true
    }
  },
  {
    path: '/tracking/:alias/edit',
    name: 'TrackingForm',
    component: () => import(/* webpackChunkName: "TrackingForm" */ '@/views/TrackingForm.vue'),
    meta: {
      authenticate: true,
      next: 'PreferencesNoStudies'
    }
  },
  {
    path: '/preferencesNoStudies/:alias/edit',
    name: 'PreferencesNoStudies',
    component: () => import(/* webpackChunkName: "PreferencesNoStudies" */ '@/views/PreferencesNoStudies.vue'),
    meta: {
      authenticate: true,
      next: 'PreferencesClinicNoStudies'
    }
  },
  {
    path: '/preferencesClinicNoStudies/:id/edit',
    name: 'PreferencesClinicNoStudies',
    component: () => import(/* webpackChunkName: "PreferencesClinicNoStudies" */ '@/views/PreferencesClinicNoStudies.vue'),
    meta: {
      authenticate: true,
      next: 'CombinedPreferences'
    }
  },
  {
    path: '/combinedPreferences/:id/edit',
    name: 'CombinedPreferences',
    component: () => import(/* webpackChunkName: "CombinedPreferences" */ '@/views/CombinedPreferences.vue'),
    meta: {
      authenticate: true,
      next: 'PatientRecommendation',
      fallback: 'InfoTreatmentNoStudies'
    }
  },
  {
    path: '/patientRecommendation/:id',
    name: 'PatientRecommendation',
    component: () => import(/* webpackChunkName: "PatientRecommendation" */ '@/views/PatientRecommendation.vue'),
    meta: {
      authenticate: true,
      next: 'InfoTreatmentNoStudies'
    }
  },
  {
    path: '/infoTreatmentNoStudies/:id/edit',
    name: 'InfoTreatmentNoStudies',
    component: () => import(/* webpackChunkName: "InfoTreatmentNoStudies" */ '@/views/InfoTreatmentNoStudies.vue'),
    meta: {
      authenticate: true,
      next: 'Dashboard'
    }
  },
  {
    path: '/resultsNoStudies/:id',
    name: 'ResultsNoStudies',
    component: () => import(/* webpackChunkName: "ResultsNoStudies" */ '@/views/ResultsNoStudies.vue'),
    meta: {
      authenticate: true
    }
  },
  {
    path: '/trackTreatmentResult/:alias',
    name: 'TrackTreatmentResult',
    component: () => import(/* webpackChunkName: "TrackTreatmentResult" */ '@/views/TrackTreatmentResult.vue'),
    meta: {
      authenticate: true,
      next: 'Dashboard'
    }
  },
  {
    path: '/treatmentResult/:alias',
    name: 'TreatmentResult',
    component: () => import(/* webpackChunkName: "TreatmentResult" */ '@/views/TreatmentResult.vue'),
    meta: {
      authenticate: true,
      next: 'Dashboard'
    }
  },
  {
    path: '/scales/adhd/:alias',
    name: 'AdHdSeverityScale',
    component: () => import(/* webpackChunkName: "AdHdSeverityScale" */ '@/views/AdHdSeverityScale.vue'),
    meta: {
      authenticate: true
    }
  },
  {
    path: '/teatment/scales/adhd/:alias',
    name: 'AdHdSeverityScaleTreatment',
    component: () => import(/* webpackChunkName: "AdHdSeverityScaleTreatment" */ '@/views/AdHdSeverityScaleTreatment.vue'),
    meta: {
      authenticate: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "About" */ '../views/About.vue'),
    meta: {
      authenticate: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    // always scroll to top
    return { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (
    to.meta?.authenticate &&
    !store.getters.loggedIn &&
    !to.path.startsWith('/api')
  ) {
    return next({
      name: 'Login'
    })
  }

  return next()
})

export default router
