/**
 * App Model
 */
export default class App {
  loading = false
  limits = {
    preferences: {
      9: 1,
      8: 2,
      7: 3
    }
  }
}
