import App from '@/models/App'
import axios from 'axios'
import { GetterTree, MutationTree, ActionTree } from 'vuex'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const appGetters = <GetterTree<App, any>>{
  loading (state) {
    return state.loading
  },
  limits (state) {
    return state.limits
  }
}

const AppModule = {
  state: new App(),

  getters: appGetters,

  mutations: <MutationTree<App>>{
    toggleLoading (state, loading) {
      state.loading = loading
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: <ActionTree<App, any>>{
    setupAxiosInterceptors ({ commit }) {
      // Add a request interceptor
      axios.interceptors.request.use((config) => {
        commit('toggleLoading', true)
        return config
      })

      // Add a response interceptor
      axios.interceptors.response.use(
        (response) => {
          commit('toggleLoading', false)
          return response
        },
        (error) => {
          commit('toggleLoading', false)
          return Promise.reject(error)
        }
      )
    }
  }
}

export default AppModule
