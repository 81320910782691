
import { Vue, Options } from 'vue-class-component'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions
} from '@headlessui/vue'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'
import { UserIcon, CheckIcon, ChevronDownIcon } from '@heroicons/vue/solid'
import { mapActions, mapGetters } from 'vuex'
import CountryFlag from 'vue-country-flag-next'

@Options({
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuIcon,
    MenuItem,
    MenuItems,
    UserIcon,
    XIcon,
    CountryFlag,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronDownIcon
  },
  methods: mapActions([
    'setupAxios',
    'setupPrivileges',
    'setupAxiosInterceptors'
  ]),
  computed: mapGetters(['name', 'email', 'token', 'loading'])
})

export default class App extends Vue {
  flags = {
    en: 'us',
    es: 'es',
    ca: 'es-ca'
  }

  // Actions
  setupAxios!: () => Promise<void>
  setupPrivileges!: () => Promise<void>
  setupAxiosInterceptors!: () => void

  async created (): Promise<void> {
    try {
      await this.setupAxios()
      await this.setupPrivileges()
    } catch {
      this.$router.push({ name: 'Logout' })
    }

    this.setupAxiosInterceptors()
  }

  isLogPage (): boolean {
    return this.$route.path !== '/login' && this.$route.path !== '/logout'
  }
}
