import User from '@/models/User'
import { Store } from 'vuex'
import { AbilityBuilder, Ability } from '@casl/ability'
import ability from '@/services/ability'

export default (store: Store<User>): void => {
  store.subscribe((mutation) => {
    switch (mutation.type) {
      case 'updatePrivileges': {
        const { data } = mutation.payload
        const { can, rules } = new AbilityBuilder(Ability)

        Object.keys(data).forEach((p) => {
          data[p].forEach((item) => {
            can(p, item)
          })
        })
        // can('view', 'recommendations')
        ability.update(rules)
        break
      }
    }
  })
}
