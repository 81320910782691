/**
 * Intervention Model
 */

const REGEX_RECOMMENDATION = /(\(J\dQ\d\))/g
const REGEX_RISK_QUALITY = /J(?<Risk>\d)Q(?<Quality>\d)/

export default class Intervention {
  pharmacDose = ''
  pharmac = ''
  dose = ''
  recommendation = ''
  strength = ''
  risk = 0
  quality = 0

  constructor (pharmacDose: string, recommendation: string) {
    this.pharmacDose = pharmacDose.toLowerCase()
    this.pharmac = pharmacDose.split('_')[0].toLowerCase()
    this.dose = pharmacDose.split('_')[1] || 'None'

    this.recommendation = recommendation
      .replace(REGEX_RECOMMENDATION, '')
      .trim()

    const riskQuality = recommendation.match(REGEX_RISK_QUALITY) // J3Q1 => Risk = 3, Quality = 1
    this.risk = (riskQuality && +riskQuality[1]) || 0
    this.quality = (riskQuality && +riskQuality[2]) || 0
    this.strength = recommendation.split(' ')[0]
  }

  rank (): number {
    switch (this.recommendation) {
      case Intervention.STRONG_FAVOR:
        return 4
      case Intervention.WEAK_FAVOR:
        return 3
      case Intervention.WEAK_AGAINST:
        return 2
      case Intervention.STRONG_AGAINST:
        return 1
      default:
        return 0
    }
  }

  static NON_VALID = 'No es poden fer recomanacions'
  static STRONG_FAVOR = 'Fort a favor'
  static WEAK_FAVOR = 'Dèbil a favor'
  static WEAK_AGAINST = 'Dèbil en contra'
  static STRONG_AGAINST = 'Fort en contra'
}
