import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import i18n from './i18n'
import { abilitiesPlugin } from '@casl/vue'
import ability from './services/ability'

import './registerServiceWorker'

import './assets/scss/index.scss'
import 'animate.css'

import { makeServer } from './server'

if (process.env.NODE_ENV === 'local') {
  makeServer()
} else if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL =
    process.env.VUE_APP_PROXY_URL + process.env.VUE_APP_API_BASE_URL
} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
}

createApp(App)
  .use(i18n)
  .use(store)
  .use(abilitiesPlugin, ability, {
    useGlobalProperties: true
  })
  .use(router, axios)
  .mount('#app')
