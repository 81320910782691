import Patient, { mergePreferences } from '@/models/Patient'
import axios from 'axios'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import LifeStage from '@/enums/LifeStage'
import Intervention from '@/models/Intervention'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const patientGetters = <GetterTree<Patient, any>>{
  patient (state) {
    return state
  },
  basic_info (state) {
    return state.basic_info
  },
  patient_information (state) {
    return state.patient_information
  },
  recommendation (state) {
    return state.recommendation
  },
  interventions (state) {
    return state.recommendation.interventions
      .filter((i) => i.rank)
      .sort((a, b) =>
        b.rank() === a.rank()
          ? a.pharmac.localeCompare(b.pharmac)
          : b.rank() - a.rank()
      )
  },
  comorbidities (state) {
    return state.comorbidities
  },
  contraindications (state) {
    return state.contraindications
  },
  drug_anamnesis (state) {
    return state.drug_anamnesis
  },
  intervention_anamnesis (state) {
    return state.intervention_anamnesis
  },
  preferences (state) {
    return state.preferences
  },
  satisfactions (state) {
    return state.satisfactions
  },
  clinicPreferences (state) {
    return state.clinicPreferences
  },
  combinedPreferences (state) {
    return state.combinedPreferences
  },
  treatment (state) {
    return state.treatment
  },
  trackTreatmentResult (state) {
    return state.trackTreatmentResult
  },
  treatmentResult (state) {
    return state.treatmentResult
  },
  severityScale (state) {
    return state.adhdSeverityScale
  },
  severityScaleTreatment (state) {
    return state.adhdSeverityScaleTreatment
  },
  lifeStage (state) {
    if (state.basic_info.age <= 9) return LifeStage.Child
    else if (state.basic_info.age <= 17) return LifeStage.Teenager
    else return LifeStage.Adult
  },
  trackingInformation (state) {
    return state.tracking
  }
}

const getDefaultState = () => {
  return new Patient()
}

const recommendationOrder = {
  14: 0, // J1Q4
  13: 1, // J1Q3
  24: 2, // J2Q4
  23: 3, // J2Q3
  12: 4, // J1Q2
  11: 5, // J1Q1
  22: 6, // J2Q2
  21: 7, // J2Q1
  '00': 8, // No avaluable
  33: 9, // J3Q3
  32: 10, // J3Q2
  31: 12, // J3Q1
  44: 13, // J4Q4
  43: 14, // J4Q3
  42: 15, // J4Q2
  41: 16, // J4Q1
  52: 17, // J5Q2
  51: 18, // J5Q1
  34: 19, // J3Q4
  53: 20, // J5Q3
  54: 21// J5Q4
}

const PatientModule = {
  state: getDefaultState(),
  getters: patientGetters,
  mutations: <MutationTree<Patient>>{
    cleanPatient (state) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.assign(state, getDefaultState())
    },
    updateRecommendation (state, recommendation) {
      const { treeResults } = recommendation
      const interventions: Intervention[] = []
      for (const key of Object.keys(treeResults)) {
        interventions.push(new Intervention(key, treeResults[key]))
      }

      interventions.sort((a, b) => recommendationOrder[`${a.risk}${a.quality}`] - recommendationOrder[`${b.risk}${b.quality}`])

      state.recommendation.interventions = interventions
      state.recommendation.analysis = recommendation.metaResults
    },
    updateComorbidities (state, comorbidities) {
      state.comorbidities = comorbidities
    },
    updateContraindications (state, contraindications) {
      state.contraindications = contraindications
    },
    updateDrugAnamnesis (state, drugAnamnesis) {
      state.drug_anamnesis = drugAnamnesis
    },
    updateInterventionAnamnesis (state, intAnamnesis) {
      state.intervention_anamnesis = intAnamnesis
    },
    updateId (state, id) {
      state.id = id
    },
    updateData (state, data) {
      state.basic_info = data
    },
    updateInfo (state, info) {
      state.patient_information = info
    },
    updateTrackingInformation (state, trackingInfo) {
      state.tracking = trackingInfo
    },
    updatePreferences (state, preferences) {
      state.preferences = preferences
      state.combinedPreferences = mergePreferences(
        state.combinedPreferences,
        preferences,
        state.clinicPreferences
      )
    },
    updateSatisfactions (state, satisfactions) {
      state.satisfactions = satisfactions
    },
    updateClinicPreferences (state, clinicPreferences) {
      state.clinicPreferences = clinicPreferences
      state.combinedPreferences = mergePreferences(
        state.combinedPreferences,
        state.preferences,
        clinicPreferences
      )
    },
    updateCombinedPreferences (state, combinedPreferences) {
      state.combinedPreferences = combinedPreferences
    },
    updateTreatment (state, treatment) {
      state.treatment = treatment
    },
    updateTreatmentResult (state, treatmentResult) {
      state.treatmentResult = treatmentResult
    },
    updateTrackTreatmentResult (state, trackTreatmentResult) {
      state.trackTreatmentResult = trackTreatmentResult
    },
    updateAdhdSeverityScale (state, scale) {
      state.adhdSeverityScale.items = scale
    },
    updateAdhdSeverityScaleTreatment (state, scale) {
      state.adhdSeverityScaleTreatment.items = scale
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: <ActionTree<Patient, any>>{
    downloadPatientDataAndInfo ({ commit }, alias) {
      commit('cleanPatient')
      return new Promise((resolve, reject) => {
        axios
          .get(`/patients/alias/${alias}`)
          .then((response) => {
            const patient = response.data.data
            commit('updateId', patient.id)
            commit('updateData', patient)
            axios
              .get(`/forms/information/${patient.id}`)
              .then((response) => {
                commit('updateInfo', response.data.data)
                resolve(true)
              })
              .catch((err) => reject(err))
          })
          .catch((err) => reject(err))
      })
    },
    createPatient ({ commit }, alias) {
      return new Promise((resolve, reject) => {
        axios
          .post('/patients', { alias })
          .then((response) => {
            const patient = response.data.data
            commit('updateId', patient.id)
            /* eslint-disable-next-line camelcase */
            const { alias, birthdate, sex, pregnant, breast_feeding, weight } = patient
            const newData = {
              alias,
              birthdate,
              sex,
              pregnant,
              breast_feeding,
              weight
            }
            commit('updateData', newData)
            resolve(patient.id)
          })
          .catch((err) => reject(err))
      })
    },
    downloadRecommendation ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/recommender/analysis/${getters.patient.id}`)
          .then((response) => {
            commit('updateRecommendation', response.data.data)
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },
    downloadTrackingInformation ({ commit }, alias = window.location.pathname.split('/')[2]) {
      commit('cleanPatient')
      return new Promise((resolve, reject) => {
        axios
          .get(`/patients/alias/${alias}`)
          .then((response) => {
            const patient = response.data.data
            commit('updateId', patient.id)
            commit('updateData', patient)
            axios
              .get(`/forms/tracking/${patient.id}`)
              .then((response) => {
                commit('updateTrackingInformation', response.data.data)
                resolve(true)
              })
              .catch((err) => reject(err))
          })
          .catch((err) => reject(err))
      })
    },
    downloadPatientPreferences ({ commit }, alias) {
      commit('cleanPatient')
      return new Promise((resolve, reject) => {
        axios
          .get(`/patients/alias/${alias}`)
          .then((response) => {
            const patient = response.data.data
            commit('updateId', patient.id)
            /* eslint-disable-next-line camelcase */
            const { alias, birthdate, sex, pregnant, breast_feeding, weight } = patient
            const newData = {
              alias,
              birthdate,
              sex,
              pregnant,
              breast_feeding,
              weight
            }
            commit('updateData', newData)
            axios
              .get(`/forms/preferences/patient/${patient.id}`)
              .then((response) => {
                commit('updatePreferences', response.data.data)
                resolve(true)
              })
              .catch((err) => reject(err))
          })
          .catch((err) => reject(err))
      })
    },
    downloadClinicPreferences ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/forms/preferences/clinic/${getters.patient.id}`)
          .then((response) => {
            commit('updateClinicPreferences', response.data.data)
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },
    downloadCombinedPreferences ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/forms/preferences/combined/${getters.patient.id}`)
          .then((response) => {
            commit('updateCombinedPreferences', response.data.data)
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },
    downloadAdhdSeverityScale ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/forms/severity_scale/${getters.patient.id}`)
          .then((response) => {
            commit('updateAdhdSeverityScale', response.data.data)
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },
    downloadAdhdSeverityScaleTreatment ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/forms/treatment/severity_scale/${getters.patient.id}`)
          .then((response) => {
            commit('updateAdhdSeverityScaleTreatment', response.data.data)
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },
    downloadComorbidities ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/forms/comorbidities/${getters.patient.id}`)
          .then((response) => {
            commit('updateComorbidities', response.data.data)
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },
    downloadContraindications ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/forms/contraindications/${getters.patient.id}`)
          .then((response) => {
            commit('updateContraindications', response.data.data)
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },
    downloadPatientSatisfactions ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/forms/satisfaction/${getters.patient.id}`)
          .then((response) => {
            commit('updateSatisfactions', response.data.data)
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },
    downloadPharmacologicalAnamnesis ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/forms/anamnesis/drugs/${getters.patient.id}`)
          .then((response) => {
            commit('updateDrugAnamnesis', response.data.data)
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },
    downloadPsychotherapeuticInterventions ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/forms/anamnesis/interventions/${getters.patient.id}`)
          .then((response) => {
            commit('updateInterventionAnamnesis', response.data.data)
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },
    downloadTreatment ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/forms/treatment/${getters.patient.id}`)
          .then((response) => {
            commit('updateTreatment', response.data.data)
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },
    downloadTreatmentResult ({ commit }, alias) {
      commit('cleanPatient')
      return new Promise((resolve, reject) => {
        axios
          .get(`/patients/alias/${alias}`)
          .then((response) => {
            const patient = response.data.data
            commit('updateId', patient.id)
            axios
              .get(`/forms/treatment/result/${patient.id}`)
              .then((response) => {
                commit('updateTreatmentResult', response.data.data)
                resolve(true)
              })
              .catch((err) => reject(err))
          })
          .catch((err) => reject(err))
      })
    },
    downloadTrackTreatmentResult ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/forms/treatment/track/${getters.patient.id}`)
          .then((response) => {
            commit('updateTrackTreatmentResult', response.data.data)
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },
    updateDataAndInfo ({ commit }, data) {
      commit('updateData', data.basic_info)
      commit('updateInfo', data.patient_information)

      return new Promise((resolve, reject) => {
        axios
          .put('/patients/' + data.id, data.basic_info)
          .then(() => {
            axios
              .put('/forms/information/' + data.id, data.patient_information)
              .then((response) => {
                resolve(response.data)
              })
              .catch((err) => reject(err))
          })
          .catch((err) => reject(err))
      })
    },
    updateAdhdSeverityScale ({ commit, getters }, { id, severityScale }) {
      const info = getters.patient_information
      const totalScale = Object.keys(severityScale.items).reduce(
        (a, c) => a + severityScale.items[c],
        0
      )

      commit('updateAdhdSeverityScale', severityScale.items)
      commit('updateInfo', { ...info, adhd_severity: totalScale })

      return new Promise((resolve, reject) => {
        axios
          .put('/forms/severity_scale/' + id, severityScale.items)
          .then(() => {
            axios
              .put('/forms/information/' + id, {
                ...info,
                adhd_severity: totalScale
              })
              .then((response) => {
                resolve(response.data)
              })
              .catch((err) => reject(err))
          })
          .catch((err) => reject(err))
      })
    },
    updateAdhdSeverityScaleTreatment (
      { commit, getters },
      { id, severityScaleTreatment }
    ) {
      const treatmentResult = getters.treatmentResult
      const totalScale = Object.keys(severityScaleTreatment.items).reduce(
        (a, c) => a + severityScaleTreatment.items[c],
        0
      )

      commit('updateAdhdSeverityScaleTreatment', severityScaleTreatment.items)
      commit('updateTreatmentResult', {
        ...treatmentResult,
        adhd_severity: totalScale
      })

      return new Promise((resolve, reject) => {
        axios
          .put(
            '/forms/treatment/severity_scale/' + id,
            severityScaleTreatment.items
          )
          .then(() => {
            axios
              .put('/forms/treatment/result/' + id, {
                ...treatmentResult,
                adhd_severity: totalScale
              })
              .then((response) => {
                resolve(response.data)
              })
              .catch((err) => reject(err))
          })
          .catch((err) => reject(err))
      })
    },
    updateComorbidities ({ commit }, { id, comorbidities }) {
      commit('updateComorbidities', comorbidities)

      return new Promise((resolve, reject) => {
        axios
          .put('/forms/comorbidities/' + id, comorbidities)
          .then((response) => {
            resolve(response.data)
          })
          .catch((err) => reject(err))
      })
    },
    updateTrackingInformation ({ commit }, { id, trackingInfo }) {
      commit('updateTrackingInformation', trackingInfo)

      return new Promise((resolve, reject) => {
        axios
          .put('/forms/tracking/' + id, trackingInfo)
          .then((response) => {
            resolve(response.data)
          })
          .catch((err) => reject(err))
      })
    },
    updateContraindications ({ commit }, { id, contraindications }) {
      commit('updateContraindications', contraindications)

      return new Promise((resolve, reject) => {
        axios
          .put('/forms/contraindications/' + id, contraindications)
          .then((response) => {
            resolve(response.data)
          })
          .catch((err) => reject(err))
      })
    },
    updateDrugAnamnesis ({ commit }, { id, drugAnamnesis }) {
      commit('updateDrugAnamnesis', drugAnamnesis)

      return new Promise((resolve, reject) => {
        axios
          .put('/forms/anamnesis/drugs/' + id, drugAnamnesis)
          .then((response) => {
            resolve(response.data)
          })
          .catch((err) => reject(err))
      })
    },
    updateInterventionAnamnesis ({ commit }, { id, intAnamnesis }) {
      commit('updateInterventionAnamnesis', intAnamnesis)

      return new Promise((resolve, reject) => {
        axios
          .put('/forms/anamnesis/interventions/' + id, intAnamnesis)
          .then((response) => {
            resolve(response.data)
          })
          .catch((err) => reject(err))
      })
    },
    updatePreferences ({ commit, getters }, { id, preferences }) {
      commit('updatePreferences', preferences)

      return new Promise((resolve, reject) => {
        axios
          .put('/forms/preferences/patient/' + id, preferences)
          .then((preferencesResponse) => {
            const combinedPreferences = getters.combinedPreferences
            axios
              .put('/forms/preferences/combined/' + id, combinedPreferences)
              .then(() => {
                resolve(preferencesResponse.data)
              })
          })
          .catch((err) => reject(err))
      })
    },
    updateSatisfactions ({ commit }, { id, satisfactions }) {
      commit('updateSatisfactions', satisfactions)

      return new Promise((resolve, reject) => {
        axios
          .put('/forms/satisfaction/' + id, satisfactions)
          .then((response) => {
            resolve(response.data)
          })
          .catch((err) => reject(err))
      })
    },
    updateClinicPreferences ({ commit, getters }, { id, clinicPreferences }) {
      commit('updateClinicPreferences', clinicPreferences)

      return new Promise((resolve, reject) => {
        axios
          .put('/forms/preferences/clinic/' + id, clinicPreferences)
          .then((clinicResponse) => {
            const combinedPreferences = getters.combinedPreferences
            axios
              .put('/forms/preferences/combined/' + id, combinedPreferences)
              .then(() => {
                resolve(clinicResponse.data)
              })
          })
          .catch((err) => reject(err))
      })
    },
    updateCombinedPreferences ({ commit }, { id, combinedPreferences }) {
      commit('updateCombinedPreferences', combinedPreferences)

      return new Promise((resolve, reject) => {
        axios
          .put('/forms/preferences/combined/' + id, combinedPreferences)
          .then((response) => {
            resolve(response.data)
          })
          .catch((err) => reject(err))
      })
    },
    updateTreatment ({ commit }, { id, treatment }) {
      commit('updateTreatment', treatment)

      return new Promise((resolve, reject) => {
        axios
          .put('/forms/treatment/' + id, treatment)
          .then((response) => {
            resolve(response.data)
          })
          .catch((err) => reject(err))
      })
    },
    updateTrackTreatmentResult ({ commit }, { id, trackTreatmentResult }) {
      commit('updateTrackTreatmentResult', trackTreatmentResult)

      return new Promise((resolve, reject) => {
        axios
          .put('/forms/treatment/track/' + id, trackTreatmentResult)
          .then((response) => {
            resolve(response.data)
          })
          .catch((err) => reject(err))
      })
    },
    updateTreatmentResult ({ commit }, { id, treatmentResult }) {
      commit('updateTreatmentResult', treatmentResult)

      return new Promise((resolve, reject) => {
        axios
          .put('/forms/treatment/result/' + id, treatmentResult)
          .then((response) => {
            resolve(response.data)
          })
          .catch((err) => reject(err))
      })
    },
    logout ({ commit }) {
      commit('logout')
    }
  }
}

export default PatientModule
