import { RawRule } from '@casl/ability'

/**
 * User Model
 */
export default class User {
  id = ''
  name = ''
  email = ''
  password = ''
  token = ''
  privileges: RawRule[] = []
}
