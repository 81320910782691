import Study from '@/models/Study'
import axios from 'axios'
import { GetterTree, MutationTree, ActionTree } from 'vuex'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const studyGetters = <GetterTree<{studies: Study[]}, any>> {
  studies (state) {
    return state.studies
  }
}

const StudyModule = {
  state: {
    studies: [
      new Study(),
      new Study(),
      new Study(),
      new Study(),
      new Study()
    ]
  },
  getters: studyGetters,
  mutations: <MutationTree<{studies: Study[]}>> {
    updateStudies (state, newState) {
      state.studies = newState
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: <ActionTree<Study[], any>>{
    getStudies ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/recommender/analysis/${id}`)
          .then((response) => {
            commit('updateStudy', response.data.data)
            resolve(true)
          })
          .catch(err => reject(err))
      })
    }
  }
}

export default StudyModule
