import Analysis from './Analysis'
import Intervention from './Intervention'

/**
 * Recommendation Model
 */

/* eslint-disable camelcase */
export default class Recommendation {
  patient_id = -1

  interventions: Intervention[] = []

  analysis: Analysis[] = []
}
